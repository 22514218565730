<template lang="pug">
  .root
    component-header-index
    <div class="container-flex">
      <div class="eventinfo">
        <div class="banner">
          <img :src="eventBanner" />
        </div>
        <div class="support-stream-title">{{ eventInfo.title }}</div>
        <div class="support-stream-user-name"><img src="/img/profile_icon_blue.svg">{{ eventInfo.streamer_name }}</div>
        <div class="support-achievement-conditions" >
          <img src="/img/book.svg"> 達成条件：
          div(class="support-achievement-conditions-item" v-if="eventInfo.target_cash")
            <img :src="getGoldIcon()" class="gold-icon">{{ eventInfo.target_cash || 0 }} (
            <img :src="getYenIcon()" class="gold-icon">{{ parseInt(eventInfo.target_cash * 1.1) || 0 }})
          div(class="support-achievement-conditions-item" v-else-if="eventInfo.target_count") {{ eventInfo.target_count }}{{ eventInfo.target_count_unit }}
          div(v-else) なし
        </div>
        <div v-if="liveEnded" class="support-achievement-conditions"><img src="/img/book.svg"> 達成結果：
          div(v-if="eventInfo.target_cash")
            <img :src="getGoldIcon()" class="gold-icon">{{ eventInfo.current_cash || 0 }} (
            <img :src="getYenIcon()" class="gold-icon">{{ parseInt(eventInfo.current_cash * 1.1) || 0 }}) 達成率: {{ eventInfo.current_percentage || 0}}%
          div(v-if="eventInfo.current_count") {{ eventInfo.current_count }}{{ eventInfo.target_count_unit }}／達成率: {{ eventInfo.current_percentage || 0}}%
        </div>
        <div class="support-scheduled-time">
          <img src="/img/clock_icon_green.svg"> {{ eventInfo.start_time  | moment("M月DD日HH:mm") }}~{{ eventInfo.end_time | moment("M月DD日HH:mm") | postfix(liveEnded ? '':'（予定）') }}
        </div>
        <div v-if="!liveEnded && liveing"class="support-scheduled-time">( {{ eventInfo.start_time  | moment("M月DD日HH:mm")| postfix('から配信中') }} )</div>
        <div v-html="eventInfo.describe"class="support-coment"></div>
        <div class="support-stream-terms">
          a(@click="$router.push({ name: 'TermsFund' })") >>クラファン配信利用規約はこちら
        </div>
      </div>
      <div class="coorperList">
        <div class="pink-label">支援プラン</div>
        <div class="right-content-border">
          <div class="support-plan" v-for="(plan,index) in plans">
            <img :src="plan.coin_icon">
            <div class="gold-point"><img :src="plan.gold_icon" class="gold-icon">{{ plan.gold_price }}</div>
            <div class="gold-point"><img :src="getYenIcon()" class="gold-icon">{{ plan.cash_price }}</div>
            <div class="gold-point-plan-name">{{ plan.plan_name }}</div>
            <div class="gold-point-describe">{{ plan.describe }}</div>
          </div>
        </div>
      </div>
    </div>
    component-footer-index
</template>

<style src="./Index.scss" lang="scss" scoped>
</style>

<script>

import { getLiveClubfunDetail } from '@/api/live'
import { getImageUrl } from '@/utils/tools'
import { getDefaultGiftList } from '@/store/modules/system'

export default {
  name: "SettingClubfunDetailIndex",
  components: { },
  filters:{
    postfix(str,postStr){
      return str + postStr
    }
  },
  data() {
    return {
      defaultGiftList:{},
      plans:[],
      eventInfo: {
        streamer_name: ''
      },
    }
  },
  props: {},
  computed: {
    liveValid(){
      return this.eventInfo.live && this.eventInfo.live.id > 0
    },
    liveing(){
      return this.eventInfo.live && this.eventInfo.live.status === this.$store.getters.enum_list.live_status_list.ONGOING
    },
    liveEnded(){
      return this.eventInfo.live && this.eventInfo.live.status === this.$store.getters.enum_list.live_status_list.ENDED
    },
    eventBanner(){
      return getImageUrl(this.eventInfo.banner_store_path)
    },
  },
  watch: {},
  mounted() {},
  async created() {
    await this.init();
  },
  updated() {},
  destroyed() {},
  methods: {
    async init() {
      const { data } = await getLiveClubfunDetail(this.$route.params.liveable_id);
      this.defaultGiftList = getDefaultGiftList();
      this.eventInfo = data;
      this.parsePlan();
    },
    parsePlan(){
      this.plans =[]
      Object.keys(this.eventInfo).forEach(key=>{
        if(key.startsWith('coin_infos_')){
          const coinInfo = this.eventInfo[key]
          const coinId = key.replace('coin_infos_','')
          if (coinInfo.gold_price || coinInfo.cash_price) {
            this.plans.push({
              coin_icon: this.getCoinIcon(coinId),
              gold_icon: this.getGoldIcon(),
              // cash_icon: this.getCashIcon(),
              gold_price: coinInfo.gold_price,
              cash_price: coinInfo.cash_price,
              describe: coinInfo.describe,
              plan_name: coinInfo.plan_name
            })
          }
        }
      })
    },
    getCoinIcon(coinId){
      return require('@/assets/gift-icon/'+this.defaultGiftList[coinId].icon)
    },
    getGoldIcon(){
      return '/img/gold-icon.png'
    },
    getYenIcon() {
      return '/img/yen-icon.png'
    },
  }
};
</script>
